export const FIELD_TYPES = {
    FARMLAND: 0,
    GRASSLAND: 1,
    MOUNTAIN: 2,
    WOOD: 3,
    HILL: 4,
    DESERT: 5,
    GOLDRIVER: 6,
    WATER: 7,
    FARMLAND_RED: 8,
    FOG: 9,
    ALPHA: 10,
};

export const RESOURCE_TYPES = {
    GRAIN: 0,
    WOOL: 1,
    ORE: 2,
    WOOD: 3,
    BRICK: 4,
    GENERIC: 5,
    GOLD: 6,
};

export const BEACH_TYPES = {
    S: 0,
    S_W: 1,
    N_W: 2,
    N: 3,
    N_E: 4,
    S_E: 5,
};

export const HARBOR_TYPES = {
    S: 0,
    S_W: 1,
    N_W: 2,
    N: 3,
    N_E: 4,
    S_E: 5,
};

export const PLAYER_COLOR_TYPES = {
    CANDIDATE: 0,
    RED: 1,
    BLUE: 2,
    GREEN: 3,
    YELLOW: 4
};

export const ROAD_DIRECTION = {
    E_W: 0,
    N_E: 1,
    N_W: 2,
};

export const CITY_LEVEL = {
    SETTLEMENT: 0,
    CITY: 1,
};

export const CITY_SIDE = {
    LEFT: 0,
    RIGHT: 1,
};

export const ROBBER_STATUSES = {
    DISABLE: 0,
    ENABLE: 1
};

export const GAME_STEPS = {
    TURN: 0,
    BUILD_MENU: 1,
    ROBBER: 2,
    NEW_GAME_OPTIONS: 3,
    ROUND_1: 4,
    ROUND_2: 5,
};

export const FIELDS_ARRAY = [
    FIELD_TYPES.FARMLAND,
    FIELD_TYPES.FARMLAND,
    FIELD_TYPES.FARMLAND,
    FIELD_TYPES.FARMLAND,
    FIELD_TYPES.MOUNTAIN,
    FIELD_TYPES.MOUNTAIN,
    FIELD_TYPES.MOUNTAIN,
    FIELD_TYPES.GRASSLAND,
    FIELD_TYPES.GRASSLAND,
    FIELD_TYPES.GRASSLAND,
    FIELD_TYPES.GRASSLAND,
    FIELD_TYPES.WOOD,
    FIELD_TYPES.WOOD,
    FIELD_TYPES.WOOD,
    FIELD_TYPES.WOOD,
    FIELD_TYPES.DESERT,
    FIELD_TYPES.HILL,
    FIELD_TYPES.HILL,
    FIELD_TYPES.HILL,
];

export const HARBORS_RES_ARRAY = [
    RESOURCE_TYPES.BRICK,
    RESOURCE_TYPES.ORE,
    RESOURCE_TYPES.WOOL,
    RESOURCE_TYPES.WOOD,
    RESOURCE_TYPES.GRAIN,
    RESOURCE_TYPES.GENERIC,
    RESOURCE_TYPES.GENERIC,
    RESOURCE_TYPES.GENERIC,
    RESOURCE_TYPES.GENERIC,
];

export const CHIPS_SPIRAL = [5, 2, 6, 3, 8, 10, 9, 12, 11, 4, 8, 10, 9, 4, 5, 6, 3, 11];

export const FIELDS_SPIRALS = [
    [{l:4,i:0},{l:6,i:0},{l:8,i:0},{l:9,i:1},{l:10,i:1},{l:9,i:2},{l:8,i:2},{l:6,i:2},{l:4,i:2},{l:3,i:2},{l:2,i:1},{l:3,i:1},{l:5,i:1},{l:7,i:1},{l:8,i:1},{l:7,i:2},{l:5,i:2},{l:4,i:1},{l:6,i:1}],
    [{l:8,i:0},{l:9,i:1},{l:10,i:1},{l:9,i:2},{l:8,i:2},{l:6,i:2},{l:4,i:2},{l:3,i:2},{l:2,i:1},{l:3,i:1},{l:4,i:0},{l:6,i:0},{l:7,i:1},{l:8,i:1},{l:7,i:2},{l:5,i:2},{l:4,i:1},{l:5,i:1},{l:6,i:1}],
    [{l:8,i:2},{l:6,i:2},{l:4,i:2},{l:3,i:2},{l:2,i:1},{l:3,i:1},{l:4,i:0},{l:6,i:0},{l:8,i:0},{l:9,i:1},{l:10,i:1},{l:9,i:2},{l:7,i:2},{l:5,i:2},{l:4,i:1},{l:5,i:1},{l:7,i:1},{l:8,i:1},{l:6,i:1}],
    [{l:4,i:2},{l:3,i:2},{l:2,i:1},{l:3,i:1},{l:4,i:0},{l:6,i:0},{l:8,i:0},{l:9,i:1},{l:10,i:1},{l:9,i:2},{l:8,i:2},{l:6,i:2},{l:5,i:2},{l:4,i:1},{l:5,i:1},{l:7,i:1},{l:8,i:1},{l:7,i:2},{l:6,i:1}],
];
