//import _ from 'lodash';
//import './style.css';
//import Icon from './assets/img/atlas_map_fields_mayfair_phone640.png';
import * as PIXI from 'pixi.js';
import {startLoader} from "./components/loader";
import {BoardComponent} from "./components/BoardComponent";
import {CommonComponent} from "./components/CommonComponent";
import {UIComponent} from "./components/UIComponent";

let width = 2534;
let height = 1734;
let scale = 0.5;
let app = new PIXI.Application({
    backgroundColor: 0x000000,
    //width: 1140, //window.innerWidth,
    //height: 780, //window.innerHeight,
    width: width * scale,
    height: height * scale,

    antialias: true,
    powerPreference: "high-performance"
});
app.stage.interactive = true;
document.body.appendChild(app.view);

const ready = atlas => {
    console.log('Load complete.');
    CommonComponent.atlas = atlas;
    CommonComponent.app = app.stage;
    app.stage.addChild(BoardComponent.create());
    app.stage.addChild(UIComponent.create());
    app.stage.roundPixels = true;
    app.stage.scale.set(scale, scale);
    return 0;
};

startLoader(ready);
/*
function component() {
    const element = document.createElement('div');
    element.innerHTML = _.join(['Hello', 'webpack'], ' ');
    element.classList.add('hello');
    const myIcon = new Image();
    myIcon.src = Icon;
    element.appendChild(myIcon);
    return element;
}

document.body.appendChild(component());*/
