export const TEXTS = {
    BUILD_BUTTON: 'Строительство',
    END_BUTTON: 'Конец хода',
    KNIGHT_BUTTON: 'Применить карту "Рыцарь"',
    DECLINE_BUTTON: 'Отмена',
    BUILD_ROAD: 'Построить дорогу',
    BUILD_SETTLEMENT: 'Построить поселение',
    BUILD_CITY: 'Построить город',

    PLAYER_TURN_START: 'Ходит игрок',
    DICE_RESULT: 'Результат броска',
    RED_PLAYER: 'КРАСНЫЙ',
    BLUE_PLAYER: 'СИНИЙ',
    GREEN_PLAYER: 'ЗЕЛЕНЫЙ',
    YELLOW_PLAYER: 'ОРАНЖЕВЫЙ',

    GET_RESOURCE: 'Получаемые ресурсы',
   /* RED_PLAYER_GET: 'Красный: ',
    BLUE_PLAYER_GET: 'Синему: ',
    GREEN_PLAYER_GET: 'Зеленому: ',
    YELLOW_PLAYER_GET: 'Оранжевому: ',*/
   START_ROUND: 'Первоначальная расстановка',

};