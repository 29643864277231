import * as PIXI from 'pixi.js';
import {CommonComponent} from "./CommonComponent";
import {DICE_RESULT, INFO_TEXT, PLAYER_STATS_TEXT} from "../physics/fonts";
import {CITY_LEVEL, GAME_STEPS, PLAYER_COLOR_TYPES} from "../physics/physics";
import {TEXTS} from "../physics/texts";
import {BoardComponent} from "./BoardComponent";

export class UIComponent {

    static getText(words, style, x, y, name = '', anchor = 0.5) {
        let text = new PIXI.Text(words, style);
        text.roundPixels = true;
        text.anchor.set(anchor);
        text.x = x;
        text.y = y;
        if (name !== '') text.name = name;
        return text;
    }

    static getPlayerBgTexture(color) {
        let texture;
        switch (color) {
            case 1:
                texture = CommonComponent.atlas.textures['player_frame_bg_red.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['player_frame_bg_blue.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['player_frame_bg_green.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['player_frame_bg_yellow.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['player_frame_bg_red.png'];
                break;
        }
        return texture;
    }

    static getPlayerTurnTexture(color) {
        let texture;
        switch (color) {
            case 1:
                texture = CommonComponent.atlas.textures['player_bg_red2.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['player_bg_blue2.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['player_bg_green2.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['player_bg_yellow2.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['player_bg_blue2.png'];
                break;
        }
        return texture;
    }

    static getPlayerText(color) {
        let text;
        switch (color) {
            case 1:
                text = TEXTS.RED_PLAYER;
                break;
            case 2:
                text = TEXTS.BLUE_PLAYER;
                break;
            case 3:
                text = TEXTS.GREEN_PLAYER;
                break;
            case 4:
                text = TEXTS.YELLOW_PLAYER;
                break;
            default:
                text = TEXTS.RED_PLAYER;
                break;
        }
        return text
    }

    static getDiceTexture(dice) {
        let texture;
        switch (dice) {
            case 1:
                texture = CommonComponent.atlas.textures['dice_1.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['dice_2.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['dice_3.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['dice_4.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['dice_5.png'];
                break;
            case 6:
                texture = CommonComponent.atlas.textures['dice_6.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['dice_1.png'];
                break;
        }
        return texture;
    }

    static drawBg() {
        let container = new PIXI.Container();
        let cell = new PIXI.Graphics();
        cell.lineStyle(1, 0xFFFFFF, 1);
        cell.beginFill(0x000000, 1);
        cell.drawRect(0, 0, 1000, 1735);
        cell.endFill();
        cell.alpha = 0.9;
        let bg;
        for (let i = 0; i < 2; i++) {
            for (let j = 0; j < 4; j++) {
                bg = new PIXI.Sprite(CommonComponent.atlas.textures['bg_wood.png']);
                bg.x = i * 512 - 10;
                bg.y = j * 512 - 50;
                container.addChild(bg);
            }
        }
        bg = new PIXI.Sprite(CommonComponent.atlas.textures['papyrus.png']);
        bg.anchor.set(0.5);
        bg.x = 420;
        bg.y = 870;
        container.addChild(bg);
        return container;
    }

    static drawStats(color) {
        let player_stats = CommonComponent.players[color - 1].stats;
        let container = new PIXI.Container();
        let res = new PIXI.Sprite(CommonComponent.atlas.textures['stats_res.png']);
        let res_text = UIComponent.getText(player_stats.res.value, PLAYER_STATS_TEXT, 80, 0);
        player_stats.res.text = res_text;
        res.anchor.set(0.5);
        res_text.anchor.set(0.5);
        let victory = new PIXI.Sprite(CommonComponent.atlas.textures['stats_victory.png']);
        let victory_text = UIComponent.getText(player_stats.victory.value, PLAYER_STATS_TEXT, 80, 50);
        player_stats.victory.text = victory_text;
        victory.anchor.set(0.5);
        victory_text.anchor.set(0.5);
        victory.y = 50;
        let knight = new PIXI.Sprite(CommonComponent.atlas.textures['stats_knight.png']);
        let knight_text = UIComponent.getText(player_stats.knight.value, PLAYER_STATS_TEXT, 80, 100);
        player_stats.knight.text = knight_text;
        knight.anchor.set(0.5);
        knight_text.anchor.set(0.5);
        knight.y = 100;
        let roads = new PIXI.Sprite(CommonComponent.atlas.textures['stats_road.png']);
        let roads_text = UIComponent.getText(player_stats.road.value, PLAYER_STATS_TEXT, 80, 150);
        player_stats.road.text = roads_text;
        roads.anchor.set(0.5);
        roads_text.anchor.set(0.5);
        roads.y = 150;
        container.addChild(res);
        container.addChild(res_text);
        container.addChild(victory);
        container.addChild(victory_text);
        container.addChild(knight);
        container.addChild(knight_text);
        container.addChild(roads);
        container.addChild(roads_text);
        return container;
    }

    static drawPlayer(color) {
        let container = new PIXI.Container();
        let frame = new PIXI.Sprite(CommonComponent.atlas.textures['player_frame.png']);
        let bg = new PIXI.Sprite(this.getPlayerBgTexture(color));
        let stats = this.drawStats(color);
        bg.x = 19;
        bg.y = 18;
        stats.x = 55;
        stats.y = 55;
        container.addChild(bg);
        container.addChild(frame);
        container.addChild(stats);
        return container;
    }

    static drawPlayers() {
        let container = new PIXI.Container();
        for (let p = 0; p < CommonComponent.player_amount; p++) {
            let player = this.drawPlayer(CommonComponent.players[p].color);
            player.x = p * (CommonComponent.player_amount === 4 ? 200 : 250);
            container.addChild(player);
        }
        container.x = CommonComponent.player_amount === 4 ? 10 : 60;
        container.y = 50;
        container.visible = false;
        CommonComponent.ui_data.container_players_cards = container;
        return container;
    }

    static drawBuildButtons() {
        let container = new PIXI.Container();

        let res_scale_coef = 0.5;
        let res_road_wood = new PIXI.Sprite(CommonComponent.atlas.textures['resource_wood.png']);
        let res_road_brick = new PIXI.Sprite(CommonComponent.atlas.textures['resource_brick.png']);
        let res_stl_wood = new PIXI.Sprite(CommonComponent.atlas.textures['resource_wood.png']);
        let res_stl_brick = new PIXI.Sprite(CommonComponent.atlas.textures['resource_brick.png']);
        let res_stl_wool = new PIXI.Sprite(CommonComponent.atlas.textures['resource_wool.png']);
        let res_stl_grain = new PIXI.Sprite(CommonComponent.atlas.textures['resource_grain.png']);

        let res_ct_grain1 = new PIXI.Sprite(CommonComponent.atlas.textures['resource_grain.png']);
        let res_ct_grain2 = new PIXI.Sprite(CommonComponent.atlas.textures['resource_grain.png']);
        let res_ct_ore1 = new PIXI.Sprite(CommonComponent.atlas.textures['resource_ore.png']);
        let res_ct_ore2 = new PIXI.Sprite(CommonComponent.atlas.textures['resource_ore.png']);
        let res_ct_ore3 = new PIXI.Sprite(CommonComponent.atlas.textures['resource_ore.png']);

        res_road_wood.scale.set(res_scale_coef);
        res_road_brick.scale.set(res_scale_coef);
        res_road_wood.x = 60;
        res_road_wood.y = 160;
        res_road_brick.x = 170;
        res_road_brick.y = 160;

        res_stl_grain.scale.set(res_scale_coef);
        res_stl_wood.scale.set(res_scale_coef);
        res_stl_brick.scale.set(res_scale_coef);
        res_stl_wool.scale.set(res_scale_coef);
        res_stl_grain.x = 285;
        res_stl_grain.y = 160;
        res_stl_wood.x = 345;
        res_stl_wood.y = 160;
        res_stl_brick.x = 405;
        res_stl_brick.y = 155;
        res_stl_wool.x = 465;
        res_stl_wool.y = 160;

        res_ct_grain1.scale.set(res_scale_coef);
        res_ct_grain2.scale.set(res_scale_coef);
        res_ct_ore1.scale.set(res_scale_coef);
        res_ct_ore2.scale.set(res_scale_coef);
        res_ct_ore3.scale.set(res_scale_coef);

        res_ct_grain1.x = 570;
        res_ct_grain1.y = 160;
        res_ct_grain2.x = 590;
        res_ct_grain2.y = 160;
        res_ct_ore1.x = 670;
        res_ct_ore1.y = 160;
        res_ct_ore2.x = 690;
        res_ct_ore2.y = 160;
        res_ct_ore3.x = 710;
        res_ct_ore3.y = 160;


        let btn_settlement = new PIXI.Sprite(CommonComponent.atlas.textures['build_settlement.png']);
        let btn_city = new PIXI.Sprite(CommonComponent.atlas.textures['build_city.png']);
        let btn_road = new PIXI.Sprite(CommonComponent.atlas.textures['build_road.png']);
        let btn_decline = new PIXI.Sprite(CommonComponent.atlas.textures['btn_game_decline.png']);
        btn_road.interactive = true;
        btn_road.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.BUILD_ROAD;
        };
        btn_road.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_road.click = () => {
            for (let i = 0; i < CommonComponent.board_data.roads.length; i++) {
                if (CommonComponent.board_data.roads[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                    CommonComponent.board_data.roads[i].sprite.visible = true;
                }
            }
        };
        btn_settlement.interactive = true;
        btn_settlement.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.BUILD_SETTLEMENT;
        };
        btn_settlement.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_settlement.click = () => {
            this.getAvailableCityPosition();
            /*for (let i = 0; i < CommonComponent.board_data.cities.length; i++) {
                if (CommonComponent.board_data.cities[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                    CommonComponent.board_data.cities[i].sprite.visible = true;
                }
            }*/
        };
        btn_city.interactive = true;
        btn_city.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.BUILD_CITY;
        };
        btn_city.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_city.click = () => {
            let player = CommonComponent.players[CommonComponent.active_player];
            for (let i = 0; i < CommonComponent.board_data.cities.length; i++) {
                if (CommonComponent.board_data.cities[i].color === player.color && CommonComponent.board_data.cities[i].level === CITY_LEVEL.SETTLEMENT) {
                    CommonComponent.board_data.cities[i].sprite.interactive = true;
                }
            }
        };
        btn_decline.interactive = true;
        btn_decline.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.DECLINE_BUTTON;
        };
        btn_decline.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_decline.click = () => {
            CommonComponent.changeStep(GAME_STEPS.TURN);
        };

        btn_road.x = 30;
        btn_settlement.x = 290;
        btn_city.x = 550;
        btn_decline.x = 340;
        btn_decline.y = 260;

        container.addChild(btn_settlement);
        container.addChild(res_road_wood);
        container.addChild(res_road_brick);
        container.addChild(res_stl_grain);
        container.addChild(res_stl_wood);
        container.addChild(res_stl_brick);
        container.addChild(res_stl_wool);
        container.addChild(res_ct_grain1);
        container.addChild(res_ct_grain2);
        container.addChild(res_ct_ore1);
        container.addChild(res_ct_ore2);
        container.addChild(res_ct_ore3);
        container.addChild(btn_city);
        container.addChild(btn_road);
        container.addChild(btn_decline);
        container.y = 1330;
        CommonComponent.ui_data.container_game_build_btns = container;
        container.visible = false;
        return container;
    }

    static drawGameButtons() {
        let container = new PIXI.Container();
        let btn_end = new PIXI.Sprite(CommonComponent.atlas.textures['btn_game_end_turn.png']);
        btn_end.x = 0;
        btn_end.anchor.set(0.5);
        let btn_build = new PIXI.Sprite(CommonComponent.atlas.textures['btn_game_build.png']);
        btn_build.anchor.set(0.5);
        btn_build.x = 250;
        let btn_knight = new PIXI.Sprite(CommonComponent.atlas.textures['btn_game_cards.png']);
        btn_knight.anchor.set(0.5);
        btn_knight.x = 500;
        container.addChild(btn_end);
        container.addChild(btn_build);
        container.addChild(btn_knight);
        container.x = 150;
        container.y = 1410;
        btn_build.interactive = true;
        btn_build.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.BUILD_BUTTON;
        };
        btn_build.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_build.click = () => {
            CommonComponent.changeStep(GAME_STEPS.BUILD_MENU);
        };
        btn_knight.interactive = true;
        btn_knight.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.KNIGHT_BUTTON;
        };
        btn_knight.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        btn_knight.click = () => {
            CommonComponent.players[CommonComponent.active_player].stats.knight.value++;
            CommonComponent.players[CommonComponent.active_player].stats.knight.text.text = CommonComponent.players[CommonComponent.active_player].stats.knight.value;
            CommonComponent.changeStep(GAME_STEPS.ROBBER);
        };
        btn_end.interactive = true;
        btn_end.mouseover = function () {
            CommonComponent.ui_data.text_hint.text = TEXTS.END_BUTTON;
        };
        btn_end.mouseout = function () {
            CommonComponent.ui_data.text_hint.text = '';
        };
        /*END TURN FUNCTION*/
        btn_end.click = () => {
            this.EndTurnFunction();
        };
        container.visible = false;
        CommonComponent.ui_data.container_game_btns = container;
        return container;

    }

    static drawGameTexts() {
        let container = new PIXI.Container();
        CommonComponent.ui_data.text_turn_player_title = UIComponent.getText(TEXTS.PLAYER_TURN_START, INFO_TEXT, 0, 0);
        CommonComponent.ui_data.text_turn_player_title.visible = false;
        container.addChild(CommonComponent.ui_data.text_turn_player_title);
        let player_color_sprite = new PIXI.Sprite(this.getPlayerTurnTexture(
            CommonComponent.players[CommonComponent.active_player].color
        ));
        player_color_sprite.anchor.set(0.5);
        player_color_sprite.x = 0;
        player_color_sprite.y = 80;
        player_color_sprite.width = 500;
        CommonComponent.ui_data.text_turn_player = UIComponent.getText(this.getPlayerText(CommonComponent.players[CommonComponent.active_player].color), INFO_TEXT, 0, 80);
        CommonComponent.ui_data.sprite_turn_player = player_color_sprite;
        CommonComponent.ui_data.text_turn_player.visible = false;
        CommonComponent.ui_data.sprite_turn_player.visible = false;
        CommonComponent.ui_data.text_dice_result = UIComponent.getText(TEXTS.DICE_RESULT, INFO_TEXT, 0, 160);
        container.addChild(CommonComponent.ui_data.text_dice_result);
        CommonComponent.ui_data.text_dice_result.visible = false;
        CommonComponent.ui_data.sprite_dice1 = new PIXI.Sprite(this.getDiceTexture(CommonComponent.dices[0]));
        CommonComponent.ui_data.sprite_dice2 = new PIXI.Sprite(this.getDiceTexture(CommonComponent.dices[1]));
        CommonComponent.ui_data.sprite_dice1.x = -200;
        CommonComponent.ui_data.sprite_dice1.y = 220;
        CommonComponent.ui_data.sprite_dice1.scale.set(2);
        CommonComponent.ui_data.sprite_dice1.visible = false;
        CommonComponent.ui_data.sprite_dice2.x = 100;
        CommonComponent.ui_data.sprite_dice2.y = 220;
        CommonComponent.ui_data.sprite_dice2.scale.set(2);
        CommonComponent.ui_data.sprite_dice2.visible = false;
        let dice_sum = CommonComponent.dices[0] + CommonComponent.dices[1];
        CommonComponent.ui_data.sprite_dice_sum = new PIXI.Sprite(BoardComponent.getChipTexture(dice_sum));
        CommonComponent.ui_data.sprite_dice_sum.anchor.set(0.5);
        CommonComponent.ui_data.sprite_dice_sum.x = 0;
        CommonComponent.ui_data.sprite_dice_sum.y = 268;
        CommonComponent.ui_data.sprite_dice_sum.visible = false;
        CommonComponent.ui_data.text_get_resource = UIComponent.getText(TEXTS.GET_RESOURCE, INFO_TEXT, 0, 370);
        CommonComponent.ui_data.text_get_resource.visible = false;

        //Player get resource text
        let player_resources = new PIXI.Container();
        let player_red_res_sprite = new PIXI.Sprite(this.getPlayerTurnTexture(PLAYER_COLOR_TYPES.RED));
        player_red_res_sprite.width = 150;
        let player_blue_res_sprite = new PIXI.Sprite(this.getPlayerTurnTexture(PLAYER_COLOR_TYPES.BLUE));
        player_blue_res_sprite.width = 150;
        player_blue_res_sprite.y = 100;
        let player_green_res_sprite = new PIXI.Sprite(this.getPlayerTurnTexture(PLAYER_COLOR_TYPES.GREEN));
        player_green_res_sprite.width = 150;
        player_green_res_sprite.y = 200;
        let player_yellow_res_sprite = new PIXI.Sprite(this.getPlayerTurnTexture(PLAYER_COLOR_TYPES.YELLOW));
        player_yellow_res_sprite.width = 150;
        player_yellow_res_sprite.y = 300;
        player_resources.addChild(player_red_res_sprite);
        player_resources.addChild(player_blue_res_sprite);
        player_resources.addChild(player_green_res_sprite);
        player_resources.addChild(player_yellow_res_sprite);
        player_resources.x = -290;
        player_resources.y = 425;
        player_resources.visible = false;
        CommonComponent.ui_data.sprite_yellow_player_resource = player_yellow_res_sprite;
        CommonComponent.ui_data.container_player_resource = player_resources;


        container.addChild(CommonComponent.ui_data.sprite_turn_player);
        container.addChild(CommonComponent.ui_data.text_turn_player);
        container.addChild(CommonComponent.ui_data.sprite_dice1);
        container.addChild(CommonComponent.ui_data.sprite_dice2);
        container.addChild(CommonComponent.ui_data.sprite_dice_sum);
        container.addChild(CommonComponent.ui_data.text_get_resource);
        container.addChild(CommonComponent.ui_data.container_player_resource);
        container.x = 420;
        container.y = 460;
        return container;
    }

    static drawOptionsBtn() {
        let sprite = new PIXI.Sprite(CommonComponent.atlas.textures['btn_options.png']);
        sprite.x = -1700;
        sprite.y = 0;
        sprite.scale.set(0.6);
        sprite.interactive = true;
        sprite.visible = false;
        sprite.click = () => {
            CommonComponent.changeStep(GAME_STEPS.NEW_GAME_OPTIONS);
        };
        return sprite;
    }

    static drawNewGameBtns() {
        let container = new PIXI.Container();
        let sprite_new_3 = new PIXI.Sprite(CommonComponent.atlas.textures['btn_start_3.png']);
        let sprite_new_4 = new PIXI.Sprite(CommonComponent.atlas.textures['btn_start_4.png']);
        sprite_new_4.x = 350;
        sprite_new_3.interactive = true;
        sprite_new_3.click = () => {
            CommonComponent.player_amount = 3;
            CommonComponent.ui_data.sprite_yellow_player_resource.visible = false;
            CommonComponent.changeStep(GAME_STEPS.ROUND_1);
        };
        sprite_new_4.interactive = true;
        sprite_new_4.click = () => {
            CommonComponent.player_amount = 4;
            CommonComponent.ui_data.sprite_yellow_player_resource.visible = true;
            CommonComponent.changeStep(GAME_STEPS.ROUND_1);
        };
        container.addChild(sprite_new_3);
        container.addChild(sprite_new_4);
        container.x = 70;
        container.y = 1350;
        container.visible = false;
        CommonComponent.ui_data.container_game_new_game_btns = container;
        return container;
    }

    static drawGetResources() {
        if (CommonComponent.ui_data.container_get_player_resource !== null) {
            CommonComponent.app_ui.removeChild(CommonComponent.ui_data.container_get_player_resource);
        }
        let res_container = new PIXI.Container();
        let pl_res_i = 0;
        for (let g_l = 0; g_l < CommonComponent.player_amount; g_l++) {
            pl_res_i = 0;
            for (let g_r = 0; g_r < CommonComponent.get_resources[g_l].length; g_r++) {
                for (let r = 0; r < CommonComponent.get_resources[g_l][g_r]; r++) {
                    let r_sprite = new PIXI.Sprite(BoardComponent.getResourceTexture(g_r));
                    r_sprite.scale.set(0.9);
                    r_sprite.x = 10 + (70 * pl_res_i);
                    r_sprite.y = 100 * g_l;
                    res_container.addChild(r_sprite);
                    pl_res_i++;
                }
            }
        }
        res_container.x = 285;
        res_container.y = 875;
        CommonComponent.ui_data.container_get_player_resource = res_container;
        CommonComponent.app_ui.addChild(CommonComponent.ui_data.container_get_player_resource);
    }

    static checkPlayerGetResource(dices_sum) {
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                if (hex.hasOwnProperty('chip')) {
                    if (hex.chip === dices_sum && (CommonComponent.board_data.robber.l !== l || CommonComponent.board_data.robber.i !== i)) {
                        for (let c = 0; c < CommonComponent.board_data.cities.length; c++) {
                            if (CommonComponent.board_data.cities[c].color !== 0 && CommonComponent.board_data.cities[c].hasOwnProperty('hex_c')) {
                                if ((CommonComponent.board_data.cities[c].hex_a.l === l && CommonComponent.board_data.cities[c].hex_a.i === i) ||
                                    (CommonComponent.board_data.cities[c].hex_b.l === l && CommonComponent.board_data.cities[c].hex_b.i === i) ||
                                    (CommonComponent.board_data.cities[c].hex_c.l === l && CommonComponent.board_data.cities[c].hex_c.i === i)
                                ) {
                                    CommonComponent.get_resources[CommonComponent.board_data.cities[c].color - 1][hex.type]++;
                                    if (CommonComponent.board_data.cities[c].level === 1) {
                                        CommonComponent.get_resources[CommonComponent.board_data.cities[c].color - 1][hex.type]++;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    static EndTurnFunction(first = false) {
        CommonComponent.get_resources = [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0]
        ];
        if (first) {
            if (CommonComponent.step === GAME_STEPS.ROUND_1) {
                if (CommonComponent.active_player === CommonComponent.player_amount - 1) {
                    CommonComponent.step = GAME_STEPS.ROUND_2;
                    UIComponent.getAvailableCityPosition();
                }
                else {
                    CommonComponent.active_player++;
                    UIComponent.getAvailableCityPosition();
                }
            }
            else {
                CommonComponent.active_player--;
                if (CommonComponent.active_player >= 0) {
                    UIComponent.getAvailableCityPosition();
                }
                else {
                    CommonComponent.changeStep(GAME_STEPS.TURN);
                    this.EndTurnFunction();
                }
            }
        }
        else {
            if (CommonComponent.active_player === CommonComponent.player_amount - 1) {
                CommonComponent.active_player = 0;
            }
            else {
                CommonComponent.active_player++;
            }
            CommonComponent.dices[0] = CommonComponent.diceRandom();
            CommonComponent.dices[1] = CommonComponent.diceRandom();
            let dices_sum = CommonComponent.dices[0] + CommonComponent.dices[1];
            CommonComponent.ui_data.sprite_dice1.texture = this.getDiceTexture(CommonComponent.dices[0]);
            CommonComponent.ui_data.sprite_dice2.texture = this.getDiceTexture(CommonComponent.dices[1]);
            CommonComponent.ui_data.sprite_dice_sum.texture = BoardComponent.getChipTexture(dices_sum);

            if ((dices_sum) === 7) {
                if (CommonComponent.ui_data.container_get_player_resource !== null) {
                    CommonComponent.app_ui.removeChild(CommonComponent.ui_data.container_get_player_resource);
                }
                CommonComponent.changeStep(GAME_STEPS.ROBBER);
            }
            else {
                this.checkPlayerGetResource(dices_sum);
                this.drawGetResources();
            }
        }
        CommonComponent.ui_data.text_turn_player.text = this.getPlayerText(CommonComponent.players[CommonComponent.active_player].color);
        CommonComponent.ui_data.sprite_turn_player.texture = this.getPlayerTurnTexture(CommonComponent.players[CommonComponent.active_player].color);
    }

    static getAvailableRoadPosition(first = 0) {
        console.log(CommonComponent.players[CommonComponent.active_player]);
        for (let i = 0; i < CommonComponent.board_data.roads.length; i++) {
            if (CommonComponent.board_data.roads[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                CommonComponent.board_data.roads[i].sprite.visible = true;
            }
        }
    }

    static getAvailableCityPosition(first = 0) {
        for (let i = 0; i < CommonComponent.board_data.cities.length; i++) {
            if (CommonComponent.board_data.cities[i].near === false && CommonComponent.board_data.cities[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                CommonComponent.board_data.cities[i].sprite.visible = true;
            }
        }
    }

    static create() {
        let container = new PIXI.Container();
        container.addChild(this.drawBg());
        container.addChild(this.drawPlayers());
        container.addChild(this.drawGameButtons());
        container.addChild(this.drawBuildButtons());
        container.addChild(this.drawGameTexts());
        container.addChild(this.drawOptionsBtn());
        container.addChild(this.drawNewGameBtns());
        let hint_text = UIComponent.getText('', PLAYER_STATS_TEXT, 80, 0);
        CommonComponent.ui_data.text_hint = hint_text;
        hint_text.anchor.set(0.5);
        hint_text.x = 420;
        hint_text.y = 1300;
        container.addChild(hint_text);
        container.x = 1685;
        container.y = 0;
        CommonComponent.app_ui = container;
        CommonComponent.changeStep(GAME_STEPS.NEW_GAME_OPTIONS);
        return container;
    }
}