export const FIELD_COORD_TEXT = {
    fontFamily: 'Arial',
    fontSize: 56,
    fill: 0xFFFFFF,
    align: 'center'
};

export const PLAYER_STATS_TEXT = {
    fontFamily: 'Arial',
    fontSize: 42,
    fill: 0xFFFFFF,
    align: 'center',
    fontWeight: 'bold',
};

export const INFO_TEXT = {
    fontFamily: 'Arial',
    fontSize: 42,
    fill: 0xFFFFFF,
    align: 'center',
    fontWeight: 'bold',
    letterSpacing: 7,
};

export const DICE_RESULT = {
    fontFamily: 'Arial',
    fontSize: 72,
    fill: 0xFFFFFF,
    align: 'center',
    fontWeight: 'bold',
    letterSpacing: 10,
};

export const HINT_TEXT = {
    fontFamily: 'Arial',
    fontSize: 24,
    fill: 0xFFFFFF,
    align: 'center',
    fontWeight: 'bold',
};


export const TOWN_DESCRIPTION_TEXT = {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 12,
    fill: 0xFFFFFF,
    align: 'center',
    wordWrap: true,
    letterSpacing: 0.3,
    wordWrapWidth: 320
};

export const TOWN_INFO_TEXT = {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 12,
    fill: 0xFFFFFF,
    align: 'center',
    wordWrap: true,
    letterSpacing: 0.2,
    wordWrapWidth: 285
};

export const TOWN_HEADER_TEXT = {
    fontFamily: 'Arial',
    fontSize: 14,
    fill: 0xFFFFFF,
    align: 'center'
};

export const TOWN_RESOURCE_TEXT = {
    fontFamily: 'Arial',
    fontSize: 11,
    fill: 0xFFFFFF,
    align: 'center'
};

export const BUILD_BUILDING_TITLE = {
    fontFamily: 'Times New Roman',
    fontSize: 16,
    fill: 0xF8E490,
    align: 'center',
    wordWrap: true,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    wordWrapWidth: 320
};

export const INFO_BUILDING_TITLE = {
    fontFamily: 'Arial',
    fontSize: 12,
    fill: 0xF8E490,
    align: 'center',
    wordWrap: true,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    wordWrapWidth: 320
};