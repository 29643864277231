import * as PIXI from 'pixi.js';
import {CommonComponent} from "./CommonComponent";
import {
    BEACH_TYPES, CHIPS_SPIRAL, CITY_LEVEL,
    CITY_SIDE, FIELD_TYPES, FIELDS_ARRAY, FIELDS_SPIRALS, GAME_STEPS,
    HARBOR_TYPES, HARBORS_MAY_ARRAY, HARBORS_RES_ARRAY, PLAYER_COLOR_TYPES,
    ROAD_DIRECTION,
    ROBBER_STATUSES
} from "../physics/physics";
import {UIComponent} from "./UIComponent";
import {FIELD_COORD_TEXT, PLAYER_STATS_TEXT} from "../physics/fonts";

export class BoardComponent {

    static FIELD_WIDTH = 340;
    static FIELD_HEIGHT = 288;

    static isEven(n) {
        return n % 2 === 0;
    }

    static getFieldTexture(type) {
        let texture;
        switch (type) {
            case 0:
                texture = CommonComponent.atlas.textures['field_farm.png'];
                break;
            case 1:
                texture = CommonComponent.atlas.textures['field_grassland.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['field_mountain.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['field_wood.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['field_hill.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['field_desert.png'];
                break;
            case 6:
                texture = CommonComponent.atlas.textures['field_gold_river.png'];
                break;
            case 7:
                texture = CommonComponent.atlas.textures['field_water.png'];
                break;
            case 8:
                texture = CommonComponent.atlas.textures['field_nothing.png'];
                break;
            case 9:
                texture = CommonComponent.atlas.textures['field_fog.png'];
                break;
            case 10:
                texture = CommonComponent.atlas.textures['field_field_alpha.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['field_water.png'];
                break;
        }
        return texture;
    }

    static getChipTexture(chip) {
        let texture;
        switch (chip) {
            case 2:
                texture = CommonComponent.atlas.textures['chip_2.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['chip_3.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['chip_4.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['chip_5.png'];
                break;
            case 6:
                texture = CommonComponent.atlas.textures['chip_6.png'];
                break;
            case 7:
                texture = CommonComponent.atlas.textures['chip_robber.png'];
                break;
            case 8:
                texture = CommonComponent.atlas.textures['chip_8.png'];
                break;
            case 9:
                texture = CommonComponent.atlas.textures['chip_9.png'];
                break;
            case 10:
                texture = CommonComponent.atlas.textures['chip_10.png'];
                break;
            case 11:
                texture = CommonComponent.atlas.textures['chip_11.png'];
                break;
            case 12:
                texture = CommonComponent.atlas.textures['chip_12.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['chip_2.png'];
                break;
        }
        return texture;
    }

    static getBeachTexture(type) {
        let texture;
        switch (type) {
            case 0:
                texture = CommonComponent.atlas.textures['beach_1.png'];
                break;
            case 1:
                texture = CommonComponent.atlas.textures['beach_2.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['beach_3.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['beach_4.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['beach_5.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['beach_6.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['beach_1.png'];
                break;
        }
        return texture;
    }

    static getHarborTexture(type) {
        let texture;
        switch (type) {
            case 0:
                texture = CommonComponent.atlas.textures['harbor_1.png'];
                break;
            case 1:
                texture = CommonComponent.atlas.textures['harbor_2.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['harbor_3.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['harbor_4.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['harbor_5.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['harbor_6.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['harbor_1.png'];
                break;
        }
        return texture;
    }

    static getResourceTexture(type) {
        let texture;
        switch (type) {
            case 0:
                texture = CommonComponent.atlas.textures['resource_grain.png'];
                break;
            case 1:
                texture = CommonComponent.atlas.textures['resource_wool.png'];
                break;
            case 2:
                texture = CommonComponent.atlas.textures['resource_ore.png'];
                break;
            case 3:
                texture = CommonComponent.atlas.textures['resource_wood.png'];
                break;
            case 4:
                texture = CommonComponent.atlas.textures['resource_brick.png'];
                break;
            case 5:
                texture = CommonComponent.atlas.textures['resource_generic.png'];
                break;
            case 6:
                texture = CommonComponent.atlas.textures['resource_gold.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['resource_generic.png'];
                break;
        }
        return texture;
    }

    static getRoadTexture(direction, color) {
        let texture;
        switch (direction) {
            case 0:
                switch (color) {
                    case 0:
                        texture = CommonComponent.atlas.textures['item_road_candidate_ew.png'];
                        break;
                    case 1:
                        texture = CommonComponent.atlas.textures['item_road_red_ew.png'];
                        break;
                    case 2:
                        texture = CommonComponent.atlas.textures['item_road_blue_ew.png'];
                        break;
                    case 3:
                        texture = CommonComponent.atlas.textures['item_road_green_ew.png'];
                        break;
                    case 4:
                        texture = CommonComponent.atlas.textures['item_road_yellow_ew.png'];
                        break;
                    default:
                        texture = CommonComponent.atlas.textures['item_road_candidate_ew.png'];
                        break;
                }
                break;
            case 1:
                switch (color) {
                    case 0:
                        texture = CommonComponent.atlas.textures['item_road_candidate_ne.png'];
                        break;
                    case 1:
                        texture = CommonComponent.atlas.textures['item_road_red_ne.png'];
                        break;
                    case 2:
                        texture = CommonComponent.atlas.textures['item_road_blue_ne.png'];
                        break;
                    case 3:
                        texture = CommonComponent.atlas.textures['item_road_green_ne.png'];
                        break;
                    case 4:
                        texture = CommonComponent.atlas.textures['item_road_yellow_ne.png'];
                        break;
                    default:
                        texture = CommonComponent.atlas.textures['item_road_candidate_ne.png'];
                        break;
                }
                break;
            case 2:
                switch (color) {
                    case 0:
                        texture = CommonComponent.atlas.textures['item_road_candidate_nw.png'];
                        break;
                    case 1:
                        texture = CommonComponent.atlas.textures['item_road_red_nw.png'];
                        break;
                    case 2:
                        texture = CommonComponent.atlas.textures['item_road_blue_nw.png'];
                        break;
                    case 3:
                        texture = CommonComponent.atlas.textures['item_road_green_nw.png'];
                        break;
                    case 4:
                        texture = CommonComponent.atlas.textures['item_road_yellow_nw.png'];
                        break;
                    default:
                        texture = CommonComponent.atlas.textures['item_road_candidate_nw.png'];
                        break;
                }
                break;
            default:
                texture = CommonComponent.atlas.textures['item_road_candidate_ne.png'];
                break;
        }
        return texture;
    }

    static getCityTexture(level, color) {
        let texture;
        switch (level) {
            case 0:
                switch (color) {
                    case 0:
                        texture = CommonComponent.atlas.textures['item_settlement_candidate.png'];
                        break;
                    case 1:
                        texture = CommonComponent.atlas.textures['item_settlement_red.png'];
                        break;
                    case 2:
                        texture = CommonComponent.atlas.textures['item_settlement_blue.png'];
                        break;
                    case 3:
                        texture = CommonComponent.atlas.textures['item_settlement_green.png'];
                        break;
                    case 4:
                        texture = CommonComponent.atlas.textures['item_settlement_yellow.png'];
                        break;
                    default:
                        texture = CommonComponent.atlas.textures['item_settlement_candidate.png'];
                        break;
                }
                break;
            case 1:
                switch (color) {
                    case 0:
                        texture = CommonComponent.atlas.textures['item_city_candidate.png'];
                        break;
                    case 1:
                        texture = CommonComponent.atlas.textures['item_city_red.png'];
                        break;
                    case 2:
                        texture = CommonComponent.atlas.textures['item_city_blue.png'];
                        break;
                    case 3:
                        texture = CommonComponent.atlas.textures['item_city_green.png'];
                        break;
                    case 4:
                        texture = CommonComponent.atlas.textures['item_city_yellow.png'];
                        break;
                    default:
                        texture = CommonComponent.atlas.textures['item_city_candidate.png'];
                        break;
                }
                break;
            default:
                texture = CommonComponent.atlas.textures['item_city_candidate.png'];
                break;
        }
        return texture;
    }

    static getRobberTexture(status) {
        let texture;
        switch (status) {
            case 0:
                texture = CommonComponent.atlas.textures['item_robber_1.png'];
                break;
            case 1:
                texture = CommonComponent.atlas.textures['item_robber_2.png'];
                break;
            default:
                texture = CommonComponent.atlas.textures['item_robber_1.png'];
                break;
        }
        return texture;
    }

    static drawFields() {
        let container = new PIXI.Container();
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                let sprite = new PIXI.Sprite(this.getFieldTexture(hex.type));
                if (!this.isEven(l)) {
                    sprite.x = i * (sprite.width * 1.5 - 12);
                }
                else {
                    sprite.x = (i * sprite.width * 1.5) + 249 - 12 * i;
                }
                sprite.y = ((sprite.height / 2) * l) - (2 * l);
                if (hex.hasOwnProperty('chip') || hex.type === FIELD_TYPES.DESERT) {
                    sprite.interactive = true;
                    sprite.line = l;
                    sprite.item = i;
                    sprite.click = (data) => {
                        let robber = CommonComponent.board_data.robber;
                        if (robber.status === ROBBER_STATUSES.ENABLE) {
                            robber.l = data.target.line;
                            robber.i = data.target.item;
                            let dx = 170;
                            let dy = 140;
                            if (!this.isEven(robber.l)) {
                                robber.sprite.x = (robber.i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
                            }
                            else {
                                robber.sprite.x = ((robber.i * this.FIELD_WIDTH * 1.5) + 249 - 12 * robber.i) + dx;
                            }
                            robber.sprite.y = ((this.FIELD_HEIGHT / 2) * robber.l - (3 * robber.l)) + dy;
                            robber.status = ROBBER_STATUSES.DISABLE;
                            robber.sprite.texture = this.getRobberTexture(ROBBER_STATUSES.DISABLE);
                            robber.sprite.interactive = false;
                            CommonComponent.changeStep(GAME_STEPS.TURN);
                        }
                    };
                }
                container.addChild(sprite);
                ///Show Board Fields coords.
                /*let text = UIComponent.getText(l + ' : ' + i, FIELD_COORD_TEXT, sprite.x + 170, sprite.y + 74);
                container.addChild(text);*/
            }
        }
        return container;
    }

    static drawBeaches() {
        let container = new PIXI.Container();
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                if (hex.hasOwnProperty('beaches')) {
                    for (let b = 0; b < hex.beaches.length; b++) {
                        let beach = hex.beaches[b];
                        let sprite = new PIXI.Sprite(this.getBeachTexture(beach));
                        let dx = 0;
                        let dy = 0;
                        if (beach === BEACH_TYPES.N) {
                            dx = 80;
                            dy = -85;
                        }
                        if (beach === BEACH_TYPES.N_W) {
                            dx = -42;
                            dy = -44;
                        }
                        if (beach === BEACH_TYPES.N_E) {
                            dx = 248;
                            dy = -44;
                        }
                        if (beach === BEACH_TYPES.S_W) {
                            dx = -50;
                            dy = 125;
                        }
                        if (beach === BEACH_TYPES.S_E) {
                            dx = 240;
                            dy = 115;
                        }
                        if (beach === BEACH_TYPES.S) {
                            dx = 80;
                            dy = 262;
                        }
                        if (!this.isEven(l)) {
                            sprite.x = (i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
                        }
                        else {
                            sprite.x = ((i * this.FIELD_WIDTH * 1.5) + 249 - 12 * i) + dx;
                        }
                        sprite.y = ((this.FIELD_HEIGHT / 2) * l) + dy;
                        container.addChild(sprite);
                    }
                }
            }
        }
        return container;
    }

    static drawHarbors() {
        let container = new PIXI.Container();
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                if (hex.hasOwnProperty('harbors')) {
                    for (let b = 0; b < hex.harbors.length; b++) {
                        let harbor = hex.harbors[b];
                        let sprite = new PIXI.Sprite(this.getHarborTexture(harbor.type));
                        let sprite_res = new PIXI.Sprite(this.getResourceTexture(harbor.res));
                        let dx = 0;
                        let dy = 0;
                        let res_dx = 0;
                        let res_dy = 0;
                        if (harbor.type === HARBOR_TYPES.N) {
                            dx = 105;
                            dy = -45;
                            res_dx = 120;
                            res_dy = -143;
                        }
                        if (harbor.type === HARBOR_TYPES.N_W) {
                            dx = -25;
                            dy = 15;
                            res_dx = -115;
                            res_dy = -25;
                        }
                        if (harbor.type === HARBOR_TYPES.N_E) {
                            dx = 275;
                            dy = 5;
                            res_dx = 345;
                            res_dy = -30;
                        }
                        if (harbor.type === HARBOR_TYPES.S_W) {
                            dx = -15;
                            dy = 155;
                            res_dx = -105;
                            res_dy = 195;
                        }
                        if (harbor.type === HARBOR_TYPES.S_E) {
                            dx = 270;
                            dy = 150;
                            res_dx = 340;
                            res_dy = 195;
                        }
                        if (harbor.type === HARBOR_TYPES.S) {
                            dx = 110;
                            dy = 262;
                            res_dx = 125;
                            res_dy = 308;
                        }
                        if (!this.isEven(l)) {
                            sprite.x = (i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
                            sprite_res.x = (i * (this.FIELD_WIDTH * 1.5 - 12)) + res_dx;
                        }
                        else {
                            sprite.x = ((i * this.FIELD_WIDTH * 1.5) + 249 - 12 * i) + dx;
                            sprite_res.x = ((i * this.FIELD_WIDTH * 1.5) + 249 - 12 * i) + res_dx;
                        }
                        sprite.y = ((this.FIELD_HEIGHT / 2) * l) + dy;
                        sprite_res.y = ((this.FIELD_HEIGHT / 2) * l) + res_dy;
                        container.addChild(sprite);
                        container.addChild(sprite_res);
                    }
                }
            }
        }
        return container;
    }

    static drawChips() {
        let container = new PIXI.Container();
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                if (hex.hasOwnProperty('chip')) {
                    let sprite = new PIXI.Sprite(this.getChipTexture(hex.chip));
                    sprite.anchor.set(0.5);
                    if (!this.isEven(l)) {
                        sprite.x = (i * (this.FIELD_WIDTH * 1.5 - 12)) + 170;
                    }
                    else {
                        sprite.x = ((i * this.FIELD_WIDTH * 1.5) + 249 - 12 * i) + 170;
                    }
                    sprite.y = (((this.FIELD_HEIGHT / 2) * l) - (2 * l)) + 144;
                    container.addChild(sprite);
                }
            }
        }
        return container;
    }

    static drawRoads() {
        let container = new PIXI.Container();
        for (let r = 0; r < CommonComponent.board_data.roads.length; r++) {
            let road = CommonComponent.board_data.roads[r];
            let direction = null;
            let dx = 0;
            let dy = 0;
            if (Math.abs(road.hex_a.l - road.hex_b.l) === 2) {
                direction = ROAD_DIRECTION.E_W;
                dx = 173;
                dy = 288;
            }
            if (road.hex_a.l - road.hex_b.l === -1) {
                direction = ROAD_DIRECTION.N_E;
                dx = 300;
                dy = 220;
            }
            if (road.hex_a.l - road.hex_b.l === 1) {
                direction = ROAD_DIRECTION.N_W;
                dx = 300;
                dy = 75;
            }
            if (direction !== null) {
                let sprite = new PIXI.Sprite(this.getRoadTexture(direction, road.color));
                sprite.anchor.set(0.5);
                if (!this.isEven(road.hex_a.l)) {
                    sprite.x = (road.hex_a.i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
                }
                else {
                    sprite.x = ((road.hex_a.i * this.FIELD_WIDTH * 1.5) + 249 - 12 * road.hex_a.i) + dx;
                }
                sprite.y = ((this.FIELD_HEIGHT / 2) * road.hex_a.l - (3 * road.hex_a.l)) + dy;

                sprite.interactive = true;
                sprite.visible = false;
                sprite.road_id = r;
                sprite.click = (data) => {
                    let road = CommonComponent.board_data.roads[data.target.road_id];
                    let player = CommonComponent.players[CommonComponent.active_player];
                    player.stats.road.value++;
                    player.stats.road.text.text = player.stats.road.value;
                    road.color = player.color;
                    road.sprite.texture = this.getRoadTexture(road.direction, road.color);
                    road.sprite.interactive = false;
                    for (let i = 0; i < CommonComponent.board_data.roads.length; i++) {
                        if (CommonComponent.board_data.roads[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                            CommonComponent.board_data.roads[i].sprite.visible = false;
                        }
                    }
                    if (CommonComponent.step === GAME_STEPS.ROUND_1 || CommonComponent.step === GAME_STEPS.ROUND_2) {
                        UIComponent.EndTurnFunction(true);
                    }
                    else {
                        CommonComponent.changeStep(GAME_STEPS.TURN);
                    }
                };
                road.direction = direction;
                road.sprite = sprite;

                container.addChild(sprite);
            }
        }
        return container;
    }

    static getHexC(city) {
        if (city.hex_a.l - city.hex_b.l === 1) {
            return {l: city.hex_b.l + 2, i: city.hex_b.i};
        }
        if (city.hex_a.l - city.hex_b.l === -1) {
            return {l: city.hex_a.l + 2, i: city.hex_a.i};
        }
        return null;
    }

    static drawCities() {
        let container = new PIXI.Container();
        for (let c = 0; c < CommonComponent.board_data.cities.length; c++) {
            let city = CommonComponent.board_data.cities[c];
            let side = null;
            let dx = 0;
            let dy = 0;

            if (city.hex_a.l - city.hex_b.l === 1) {
                side = CITY_SIDE.LEFT;
                dx = 335;
                dy = 145;
            }

            if (city.hex_a.l - city.hex_b.l === -1) {
                side = CITY_SIDE.RIGHT;
                dx = 260;
                dy = 280;
            }

            if (side !== null) {
                let sprite = new PIXI.Sprite(this.getCityTexture(city.level, city.color));
                sprite.anchor.set(0.5);
                if (!this.isEven(city.hex_a.l)) {
                    sprite.x = (city.hex_a.i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
                }
                else {
                    sprite.x = ((city.hex_a.i * this.FIELD_WIDTH * 1.5) + 249 - 12 * city.hex_a.i) + dx;
                }
                sprite.y = ((this.FIELD_HEIGHT / 2) * city.hex_a.l - (3 * city.hex_a.l)) + dy;

                sprite.interactive = true;
                sprite.visible = false;
                sprite.city_id = c;
                sprite.click = (data) => {
                    let city = CommonComponent.board_data.cities[data.target.city_id];
                    let player = CommonComponent.players[CommonComponent.active_player];
                    player.stats.victory.value++;
                    player.stats.victory.text.text = player.stats.victory.value;
                    if (city.level === CITY_LEVEL.SETTLEMENT && city.color === player.color) {
                        city.level++;
                        city.sprite.texture = this.getCityTexture(CITY_LEVEL.CITY, city.color);
                        for (let i = 0; i < CommonComponent.board_data.cities.length; i++) {
                            if (CommonComponent.board_data.cities[i].color === player.color) {
                                CommonComponent.board_data.cities[i].sprite.interactive = false;
                            }
                        }
                    }
                    if (city.color === PLAYER_COLOR_TYPES.CANDIDATE) {
                        city.color = player.color;
                        city.sprite.texture = this.getCityTexture(CITY_LEVEL.SETTLEMENT, city.color);
                        city.hex_c = this.getHexC(city);

                        for (let i = 0; i < CommonComponent.board_data.cities.length; i++) {
                            if (CommonComponent.board_data.cities[i].color === PLAYER_COLOR_TYPES.CANDIDATE) {
                                CommonComponent.board_data.cities[i].sprite.visible = false;
                            }
                        }

                        for (let n_c = 0; n_c < CommonComponent.board_data.cities.length; n_c++) {
                            let check_city = CommonComponent.board_data.cities[n_c];
                            if (!(city.hex_a.l === check_city.hex_a.l && city.hex_a.i === check_city.hex_a.i &&
                                city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i)) {
                                if (check_city.hex_c === null) {
                                    check_city.hex_c = this.getHexC(check_city);
                                }
                                //a
                                if ((city.hex_a.l === check_city.hex_a.l && city.hex_a.i === check_city.hex_a.i) &&
                                    (city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_a.l === check_city.hex_a.l && city.hex_a.i === check_city.hex_a.i) &&
                                    (city.hex_b.l === check_city.hex_c.l && city.hex_b.i === check_city.hex_c.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_a.l === check_city.hex_a.l && city.hex_a.i === check_city.hex_a.i) &&
                                    (city.hex_c.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i)) {
                                    check_city.near = true;
                                }
                                //b
                                if ((city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i) &&
                                    (city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i) &&
                                    (city.hex_a.l === check_city.hex_c.l && city.hex_a.i === check_city.hex_c.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i) &&
                                    (city.hex_c.l === check_city.hex_a.l && city.hex_c.i === check_city.hex_a.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i) &&
                                    (city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i)) {
                                    check_city.near = true;
                                }
                                //c
                                if ((city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i) &&
                                    (city.hex_a.l === check_city.hex_b.l && city.hex_a.i === check_city.hex_b.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i) &&
                                    (city.hex_b.l === check_city.hex_a.l && city.hex_b.i === check_city.hex_a.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i) &&
                                    (city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i) &&
                                    (city.hex_a.l === check_city.hex_a.l && city.hex_a.i === check_city.hex_a.i)) {
                                    check_city.near = true;
                                }
                                if ((city.hex_c.l === check_city.hex_c.l && city.hex_c.i === check_city.hex_c.i) &&
                                    (city.hex_b.l === check_city.hex_b.l && city.hex_b.i === check_city.hex_b.i)) {
                                    check_city.near = true;
                                }
                            }
                        }
                    }
                    sprite.interactive = false;
                    if (CommonComponent.step === GAME_STEPS.ROUND_1 || CommonComponent.step === GAME_STEPS.ROUND_2) {
                        UIComponent.getAvailableRoadPosition();
                        if (CommonComponent.step === GAME_STEPS.ROUND_2) {
                            CommonComponent.ui_data.text_get_resource.visible = true;
                            CommonComponent.ui_data.container_player_resource.visible = true;
                            if (CommonComponent.board_data.fields[city.hex_a.l][city.hex_a.i].hasOwnProperty('chip')) {
                                CommonComponent.get_resources[CommonComponent.active_player][CommonComponent.board_data.fields[city.hex_a.l][city.hex_a.i].type]++;
                            }
                            if (CommonComponent.board_data.fields[city.hex_b.l][city.hex_b.i].hasOwnProperty('chip')) {
                                CommonComponent.get_resources[CommonComponent.active_player][CommonComponent.board_data.fields[city.hex_b.l][city.hex_b.i].type]++;
                            }
                            if (CommonComponent.board_data.fields[city.hex_c.l][city.hex_c.i].hasOwnProperty('chip')) {
                                CommonComponent.get_resources[CommonComponent.active_player][CommonComponent.board_data.fields[city.hex_c.l][city.hex_c.i].type]++;
                            }
                            UIComponent.drawGetResources();
                        }
                    }
                    else {
                        CommonComponent.changeStep(GAME_STEPS.TURN);
                    }
                };
                city.sprite = sprite;
                container.addChild(sprite);
            }
        }
        return container;
    }

    static drawRobber() {
        let container = new PIXI.Container();
        let robber = CommonComponent.board_data.robber;
        let dx = 170;
        let dy = 140;
        let sprite = new PIXI.Sprite(this.getRobberTexture(robber.status));
        sprite.anchor.set(0.5);
        if (!this.isEven(robber.l)) {
            sprite.x = (robber.i * (this.FIELD_WIDTH * 1.5 - 12)) + dx;
        }
        else {
            sprite.x = ((robber.i * this.FIELD_WIDTH * 1.5) + 249 - 12 * robber.i) + dx;
        }
        sprite.y = ((this.FIELD_HEIGHT / 2) * robber.l - (3 * robber.l)) + dy;

        //sprite.interactive = false;
        sprite.visible = true;
        /*sprite.click = () => {
            let robber = CommonComponent.board_data.robber;
            if (robber.status === ROBBER_STATUSES.DISABLE) {
                robber.status = ROBBER_STATUSES.ENABLE;
            }
            else {
                robber.status = ROBBER_STATUSES.DISABLE;
            }
            robber.sprite.texture = this.getRobberTexture(robber.status);
        };*/
        robber.sprite = sprite;
        container.addChild(sprite);
        return container;
    }

    static random() {
        let fields_array = CommonComponent.shuffle(FIELDS_ARRAY);
        let harbors_res_array = CommonComponent.shuffle(HARBORS_RES_ARRAY);
        let f_t = 0;
        let f_r_h = 0;
        for (let l = 0; l < CommonComponent.board_data.fields.length; l++) {
            let line = CommonComponent.board_data.fields[l];
            for (let i = 0; i < line.length; i++) {
                let hex = line[i];
                if (hex.type !== FIELD_TYPES.WATER) {
                    hex.type = fields_array[f_t];
                    if (hex.type === FIELD_TYPES.DESERT) {
                        CommonComponent.board_data.robber.l = l;
                        CommonComponent.board_data.robber.i = i;
                        delete hex.chip;
                    }
                    f_t++;
                }
                if (hex.hasOwnProperty('harbors')) {
                    for (let h = 0; h < hex.harbors.length; h++) {
                        hex.harbors[h].res = harbors_res_array[f_r_h];
                        f_r_h++;
                    }
                }
            }
        }
        let field_spiral_variant = Math.floor(Math.random() * 4);
        let chip_i = 0;
        for (let f = 0; f < FIELDS_SPIRALS[field_spiral_variant].length; f++) {
            let f_item = FIELDS_SPIRALS[field_spiral_variant][f];
            if (CommonComponent.board_data.fields[f_item.l][f_item.i].type !== FIELD_TYPES.DESERT) {
                CommonComponent.board_data.fields[f_item.l][f_item.i].chip = CHIPS_SPIRAL[chip_i];
                chip_i++;
            }
        }
    }

    static create() {
        this.random();
        let container = new PIXI.Container();
        container.addChild(this.drawFields());
        container.addChild(this.drawBeaches());
        container.addChild(this.drawHarbors());
        container.addChild(this.drawChips());
        container.addChild(this.drawRoads());
        container.addChild(this.drawCities());
        container.addChild(this.drawRobber());
        container.x = -90;
        container.y = -142;
        return container;
    }
}