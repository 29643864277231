import {
    BEACH_TYPES,
    FIELD_TYPES, GAME_STEPS,
    HARBOR_TYPES,
    PLAYER_COLOR_TYPES,
    RESOURCE_TYPES,
    ROBBER_STATUSES
} from "../physics/physics";
import {BoardComponent} from "./BoardComponent";
import {TEXTS} from "../physics/texts";
import {UIComponent} from "./UIComponent";

export class CommonComponent {
    static atlas = {};
    static app = null;
    static app_ui = null;
    static step = GAME_STEPS.BUILD_MENU;
    static player_amount = 4;
    static active_player = 0;
    static dices = [6, 6];
    static get_resources = [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0]
    ];
    static players = [
        {
            color: PLAYER_COLOR_TYPES.RED,
            cities: [],
            chips: [],
            roads: [],
            stats: {
                res: {value: 0, text: null},
                victory: {value: 0, text: null},
                knight: {value: 0, text: null},
                road: {value: 0, text: null},
            },
        },
        {
            color: PLAYER_COLOR_TYPES.BLUE,
            cities: [],
            chips: [],
            roads: [],
            stats: {
                res: {value: 0, text: null},
                victory: {value: 0, text: null},
                knight: {value: 0, text: null},
                road: {value: 0, text: null},
            }
        },
        {
            color: PLAYER_COLOR_TYPES.GREEN,
            cities: [],
            chips: [],
            roads: [],
            stats: {
                res: {value: 0, text: null},
                victory: {value: 0, text: null},
                knight: {value: 0, text: null},
                road: {value: 0, text: null},
            }
        },
        {
            color: PLAYER_COLOR_TYPES.YELLOW,
            cities: [],
            chips: [],
            roads: [],
            stats: {
                res: {value: 0, text: null},
                victory: {value: 0, text: null},
                knight: {value: 0, text: null},
                road: {value: 0, text: null},
            }
        }
    ];
    static board_data = {
        robber: {
            l: 6,
            i: 1,
            status: ROBBER_STATUSES.DISABLE,
            chip: 7,
            sprite: null
        },
        fields: [
            [
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {type: FIELD_TYPES.WATER},
                {
                    type: FIELD_TYPES.FARMLAND,
                    beaches: [BEACH_TYPES.N, BEACH_TYPES.N_W, BEACH_TYPES.N_E],
                    harbors: [
                        {type: HARBOR_TYPES.N, res: RESOURCE_TYPES.BRICK},
                        /* {type: HARBOR_TYPES.N_W, res: RESOURCE_TYPES.GRAIN},
                         {type: HARBOR_TYPES.N_E, res: RESOURCE_TYPES.GENERIC},*/
                    ],
                    chip: 9,
                },
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {type: FIELD_TYPES.WATER},
                {
                    type: FIELD_TYPES.WOOD,
                    beaches: [BEACH_TYPES.N, BEACH_TYPES.N_W],
                    harbors: [
                        /* {type: HARBOR_TYPES.N, res: RESOURCE_TYPES.BRICK},*/
                        {type: HARBOR_TYPES.N_W, res: RESOURCE_TYPES.WOOL},
                    ],
                    chip: 8,
                },
                {
                    type: FIELD_TYPES.FARMLAND,
                    beaches: [BEACH_TYPES.N, BEACH_TYPES.N_E],
                    harbors: [
                        /* {type: HARBOR_TYPES.N, res: RESOURCE_TYPES.BRICK},*/
                        {type: HARBOR_TYPES.N_E, res: RESOURCE_TYPES.WOOD},
                    ],
                    chip: 12,
                },
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {
                    type: FIELD_TYPES.HILL,
                    beaches: [BEACH_TYPES.N, BEACH_TYPES.N_W, BEACH_TYPES.S_W],
                    /*harbors: [
                        {type: HARBOR_TYPES.N, res: RESOURCE_TYPES.BRICK},
                        {type: HARBOR_TYPES.N_W, res: RESOURCE_TYPES.GENERIC},
                        {type: HARBOR_TYPES.S_W, res: RESOURCE_TYPES.ORE},
                    ],*/
                    chip: 5,
                },
                {
                    type: FIELD_TYPES.WOOD,
                    chip: 11,
                },
                {
                    type: FIELD_TYPES.MOUNTAIN,
                    beaches: [BEACH_TYPES.N, BEACH_TYPES.N_E, BEACH_TYPES.S_E],
                    harbors: [
                        /*  {type: HARBOR_TYPES.N, res: RESOURCE_TYPES.BRICK},*/
                        {type: HARBOR_TYPES.N_E, res: RESOURCE_TYPES.GRAIN},
                        /*{type: HARBOR_TYPES.S_E, res: RESOURCE_TYPES.ORE},*/
                    ],
                    chip: 10,
                },
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.MOUNTAIN,
                    chip: 3,
                },
                {
                    type: FIELD_TYPES.HILL,
                    chip: 6,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                },
            ],
            [
                {
                    type: FIELD_TYPES.FARMLAND,
                    beaches: [BEACH_TYPES.N_W, BEACH_TYPES.S_W],
                    harbors: [
                        /*      {type: HARBOR_TYPES.N_W, res: RESOURCE_TYPES.ORE},*/
                        {type: HARBOR_TYPES.S_W, res: RESOURCE_TYPES.GENERIC},
                    ],
                    chip: 6,
                },
                {
                    type: FIELD_TYPES.DESERT
                },
                {
                    type: FIELD_TYPES.GRASSLAND,
                    beaches: [BEACH_TYPES.N_E, BEACH_TYPES.S_E],
                    harbors: [
                        /* {type: HARBOR_TYPES.N_E, res: RESOURCE_TYPES.GENERIC},*/
                        {type: HARBOR_TYPES.S_E, res: RESOURCE_TYPES.GENERIC},
                    ],
                    chip: 2,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                }
            ],
            [
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.FARMLAND,
                    chip: 4,
                },
                {
                    type: FIELD_TYPES.GRASSLAND,
                    chip: 4,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                },
            ],
            [
                {
                    type: FIELD_TYPES.GRASSLAND,
                    beaches: [BEACH_TYPES.N_W, BEACH_TYPES.S_W, BEACH_TYPES.S],
                    /*harbors: [
                        {type: HARBOR_TYPES.N_W, res: RESOURCE_TYPES.GENERIC},
                        {type: HARBOR_TYPES.S_W, res: RESOURCE_TYPES.GENERIC},
                        {type: HARBOR_TYPES.S, res: RESOURCE_TYPES.GENERIC},
                    ],*/
                    chip: 11,
                },
                {
                    type: FIELD_TYPES.WOOD,
                    chip: 3,
                },
                {
                    type: FIELD_TYPES.WOOD,
                    beaches: [BEACH_TYPES.N_E, BEACH_TYPES.S_E, BEACH_TYPES.S],
                    /* harbors: [
                         {type: HARBOR_TYPES.N_E, res: RESOURCE_TYPES.GENERIC},
                         {type: HARBOR_TYPES.S_E, res: RESOURCE_TYPES.GENERIC},
                         {type: HARBOR_TYPES.S, res: RESOURCE_TYPES.GENERIC},
                     ],*/
                    chip: 9,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                }
            ],
            [
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.GRASSLAND,
                    beaches: [BEACH_TYPES.S_W, BEACH_TYPES.S],
                    harbors: [
                        {type: HARBOR_TYPES.S_W, res: RESOURCE_TYPES.GENERIC},
                        /*{type: HARBOR_TYPES.S, res: RESOURCE_TYPES.GENERIC},*/
                    ],
                    chip: 5,
                },
                {
                    type: FIELD_TYPES.HILL,
                    beaches: [BEACH_TYPES.S_E, BEACH_TYPES.S],
                    harbors: [
                        {type: HARBOR_TYPES.S_E, res: RESOURCE_TYPES.GENERIC},
                        /*{type: HARBOR_TYPES.S, res: RESOURCE_TYPES.GENERIC},*/
                    ],
                    chip: 10,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                },
            ],
            [
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.MOUNTAIN,
                    beaches: [BEACH_TYPES.S_W, BEACH_TYPES.S_E, BEACH_TYPES.S],
                    harbors: [
                        {type: HARBOR_TYPES.S, res: RESOURCE_TYPES.BRICK},
                        /*{type: HARBOR_TYPES.S_W, res: RESOURCE_TYPES.GENERIC},
                        {type: HARBOR_TYPES.S_E, res: RESOURCE_TYPES.WOOL},*/
                    ],
                    chip: 8,
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                },
                {
                    type: FIELD_TYPES.WATER
                }
            ],
            [
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
            [
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER},
                {type: FIELD_TYPES.WATER}
            ],
        ],
        roads: [
            {
                hex_a: {l: 2, i: 0},
                hex_b: {l: 4, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 0},
                hex_b: {l: 6, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 0},
                hex_b: {l: 8, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 0},
                hex_b: {l: 10, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 1, i: 1},
                hex_b: {l: 3, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 3, i: 1},
                hex_b: {l: 5, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 1},
                hex_b: {l: 7, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 1},
                hex_b: {l: 9, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 1},
                hex_b: {l: 11, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 0, i: 1},
                hex_b: {l: 2, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 2, i: 1},
                hex_b: {l: 4, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 1},
                hex_b: {l: 6, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 1},
                hex_b: {l: 8, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 1},
                hex_b: {l: 10, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 10, i: 1},
                hex_b: {l: 12, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 1, i: 2},
                hex_b: {l: 3, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 3, i: 2},
                hex_b: {l: 5, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 2},
                hex_b: {l: 7, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 2},
                hex_b: {l: 9, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 2},
                hex_b: {l: 11, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 2, i: 2},
                hex_b: {l: 4, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 2},
                hex_b: {l: 6, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 2},
                hex_b: {l: 8, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 2},
                hex_b: {l: 10, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 3, i: 0},
                hex_b: {l: 4, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 0},
                hex_b: {l: 6, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 0},
                hex_b: {l: 8, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 2, i: 0},
                hex_b: {l: 3, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 0},
                hex_b: {l: 5, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 0},
                hex_b: {l: 7, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 0},
                hex_b: {l: 9, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 1, i: 1},
                hex_b: {l: 2, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 3, i: 1},
                hex_b: {l: 4, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 1},
                hex_b: {l: 6, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 1},
                hex_b: {l: 8, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 1},
                hex_b: {l: 10, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
//
            {
                hex_a: {l: 2, i: 1},
                hex_b: {l: 3, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 1},
                hex_b: {l: 5, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 1},
                hex_b: {l: 7, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 1},
                hex_b: {l: 9, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 10, i: 1},
                hex_b: {l: 11, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 3, i: 2},
                hex_b: {l: 4, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 2},
                hex_b: {l: 6, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 2},
                hex_b: {l: 8, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 2},
                hex_b: {l: 10, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 4, i: 2},
                hex_b: {l: 5, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 2},
                hex_b: {l: 7, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 2},
                hex_b: {l: 9, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
//
            {
                hex_a: {l: 5, i: 0},
                hex_b: {l: 4, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 0},
                hex_b: {l: 6, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 0},
                hex_b: {l: 8, i: 0},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 4, i: 0},
                hex_b: {l: 3, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 0},
                hex_b: {l: 5, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 0},
                hex_b: {l: 7, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 10, i: 0},
                hex_b: {l: 9, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 3, i: 1},
                hex_b: {l: 2, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 1},
                hex_b: {l: 4, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 1},
                hex_b: {l: 6, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 1},
                hex_b: {l: 8, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 11, i: 1},
                hex_b: {l: 10, i: 1},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 2, i: 1},
                hex_b: {l: 1, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 4, i: 1},
                hex_b: {l: 3, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 1},
                hex_b: {l: 5, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 1},
                hex_b: {l: 7, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 10, i: 1},
                hex_b: {l: 9, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 3, i: 2},
                hex_b: {l: 2, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 5, i: 2},
                hex_b: {l: 4, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 7, i: 2},
                hex_b: {l: 6, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 9, i: 2},
                hex_b: {l: 8, i: 2},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            //
            {
                hex_a: {l: 4, i: 2},
                hex_b: {l: 3, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 6, i: 2},
                hex_b: {l: 5, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
            {
                hex_a: {l: 8, i: 2},
                hex_b: {l: 7, i: 3},
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                sprite: null,
                direction: null
            },
        ],
        cities: [
            {
                hex_a: {l: 3, i: 0},
                hex_b: {l: 4, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 0},
                hex_b: {l: 6, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 0},
                hex_b: {l: 8, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 3, i: 0},
                hex_b: {l: 2, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 2, i: 0},
                hex_b: {l: 3, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 0},
                hex_b: {l: 4, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 4, i: 0},
                hex_b: {l: 5, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 0},
                hex_b: {l: 6, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 0},
                hex_b: {l: 7, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 9, i: 0},
                hex_b: {l: 8, i: 0},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 0},
                hex_b: {l: 9, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 2, i: 0},
                hex_b: {l: 1, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 1, i: 1},
                hex_b: {l: 2, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 4, i: 0},
                hex_b: {l: 3, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 3, i: 1},
                hex_b: {l: 4, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 0},
                hex_b: {l: 5, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 1},
                hex_b: {l: 6, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 0},
                hex_b: {l: 7, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 1},
                hex_b: {l: 8, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 10, i: 0},
                hex_b: {l: 9, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 9, i: 1},
                hex_b: {l: 10, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 1, i: 1},
                hex_b: {l: 0, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 0, i: 1},
                hex_b: {l: 1, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 3, i: 1},
                hex_b: {l: 2, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 2, i: 1},
                hex_b: {l: 3, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 1},
                hex_b: {l: 4, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 4, i: 1},
                hex_b: {l: 5, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 1},
                hex_b: {l: 6, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 1},
                hex_b: {l: 7, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 9, i: 1},
                hex_b: {l: 8, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 1},
                hex_b: {l: 9, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 11, i: 1},
                hex_b: {l: 10, i: 1},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 10, i: 1},
                hex_b: {l: 11, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 2, i: 1},
                hex_b: {l: 1, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 1, i: 2},
                hex_b: {l: 2, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 4, i: 1},
                hex_b: {l: 3, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 3, i: 2},
                hex_b: {l: 4, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 1},
                hex_b: {l: 5, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 2},
                hex_b: {l: 6, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 1},
                hex_b: {l: 7, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 2},
                hex_b: {l: 8, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 10, i: 1},
                hex_b: {l: 9, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 9, i: 2},
                hex_b: {l: 10, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 3, i: 2},
                hex_b: {l: 2, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 2, i: 2},
                hex_b: {l: 3, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 5, i: 2},
                hex_b: {l: 4, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 4, i: 2},
                hex_b: {l: 5, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 7, i: 2},
                hex_b: {l: 6, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 2},
                hex_b: {l: 7, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 9, i: 2},
                hex_b: {l: 8, i: 2},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 2},
                hex_b: {l: 9, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            //
            {
                hex_a: {l: 4, i: 2},
                hex_b: {l: 3, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 6, i: 2},
                hex_b: {l: 5, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
            {
                hex_a: {l: 8, i: 2},
                hex_b: {l: 7, i: 3},
                hex_c: null,
                color: PLAYER_COLOR_TYPES.CANDIDATE,
                near: false,
                sprite: null,
                level: 0
            },
        ]
    };
    static ui_data = {
        container_players_cards: null,
        container_game_btns: null,
        container_game_build_btns: null,
        container_game_new_game_btns: null,
        text_hint: null,
        text_turn_player_title: null,
        text_turn_player: null,
        text_dice_result: null,
        sprite_dice1: null,
        sprite_dice2: null,
        sprite_dice_sum: null,
        sprite_turn_player: null,
        text_get_resource: null,
        container_player_resource: null,
        sprite_yellow_player_resource: null,
        container_get_player_resource: null,
    };

    static shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    static diceRandom() {
        let min = 1;
        let max = 6;
        let randomNumber;
        let total = [];
        let randomBuffer;
        min = Math.ceil(min);
        max = Math.floor(max);
        for (let i = 0; i < 2; i++) {
            randomBuffer = new Uint32Array(1);
            window.crypto.getRandomValues(randomBuffer);
            randomNumber = randomBuffer[0] / (0xffffffff + 1);
            total[i] = Math.floor(randomNumber * (max - min + 1)) + min;
        }
        return total[0]+total[1];
    }

    static changeStep(step) {
        if (this.step === GAME_STEPS.TURN) {
            if (step === GAME_STEPS.BUILD_MENU) {
                this.ui_data.container_game_btns.visible = false;
                this.ui_data.container_game_build_btns.visible = true;
                CommonComponent.ui_data.text_hint.text = '';
                this.step = step;
            }
            if (step === GAME_STEPS.ROBBER) {
                this.ui_data.container_game_btns.visible = false;
                CommonComponent.ui_data.text_hint.text = '';
                this.board_data.robber.status = ROBBER_STATUSES.ENABLE;
                this.board_data.robber.sprite.texture = BoardComponent.getRobberTexture(this.board_data.robber.status);
                this.board_data.robber.sprite.interactive = true;
                this.step = step;
            }
        }
        if (this.step === GAME_STEPS.BUILD_MENU) {
            if (step === GAME_STEPS.TURN) {
                this.ui_data.container_game_btns.visible = true;
                this.ui_data.container_game_build_btns.visible = false;
                CommonComponent.ui_data.text_hint.text = '';
                this.step = step;
            }
            if (step === GAME_STEPS.NEW_GAME_OPTIONS) {
                CommonComponent.ui_data.container_game_btns.visible = false;
                CommonComponent.ui_data.text_hint.text = '';
                CommonComponent.ui_data.text_turn_player_title.visible = false;
                CommonComponent.ui_data.text_turn_player.visible = false;
                CommonComponent.ui_data.sprite_turn_player.visible = false;
                CommonComponent.ui_data.text_dice_result.visible = false;
                CommonComponent.ui_data.sprite_dice1.visible = false;
                CommonComponent.ui_data.sprite_dice2.visible = false;
                CommonComponent.ui_data.sprite_dice_sum.visible = false;
                CommonComponent.ui_data.text_get_resource.visible = false;
                CommonComponent.ui_data.container_player_resource.visible = false;
                CommonComponent.ui_data.container_game_build_btns.visible = false;
                if (CommonComponent.ui_data.container_get_player_resource !== null) {
                    CommonComponent.ui_data.container_get_player_resource.visible = false;
                }
                if (CommonComponent.ui_data.container_players_cards !== null) {
                    CommonComponent.app_ui.removeChild(CommonComponent.ui_data.container_players_cards);
                }
                CommonComponent.ui_data.container_game_new_game_btns.visible = true;
                this.step = step;
            }
        }
        if (this.step === GAME_STEPS.ROBBER) {
            if (step === GAME_STEPS.TURN) {
                this.ui_data.container_game_btns.visible = true;
                CommonComponent.ui_data.text_hint.text = '';
                this.step = step;
            }
        }
        if (this.step === GAME_STEPS.NEW_GAME_OPTIONS) {
            if (step === GAME_STEPS.ROUND_1) {
                if (CommonComponent.ui_data.container_players_cards !== null) {
                    CommonComponent.app_ui.removeChild(CommonComponent.ui_data.container_players_cards);
                }
                CommonComponent.app_ui.addChild(UIComponent.drawPlayers());
                CommonComponent.ui_data.container_players_cards.visible = true;
                CommonComponent.ui_data.text_hint.text = TEXTS.START_ROUND;
                CommonComponent.ui_data.text_turn_player_title.visible = true;
                CommonComponent.ui_data.sprite_turn_player.visible = true;
                CommonComponent.ui_data.text_turn_player.visible = true;
                this.ui_data.container_game_new_game_btns.visible = false;
                UIComponent.getAvailableCityPosition();
                this.step = step;
            }
        }
        if (this.step === GAME_STEPS.ROUND_2) {
            if (step === GAME_STEPS.TURN) {
                CommonComponent.ui_data.container_game_btns.visible = true;
                CommonComponent.ui_data.text_hint.text = '';
                CommonComponent.ui_data.text_turn_player_title.visible = true;
                CommonComponent.ui_data.text_turn_player.visible = true;
                CommonComponent.ui_data.text_dice_result.visible = true;
                CommonComponent.ui_data.sprite_dice1.visible = true;
                CommonComponent.ui_data.sprite_dice2.visible = true;
                CommonComponent.ui_data.sprite_dice_sum.visible = true;
                CommonComponent.ui_data.text_get_resource.visible = true;
                CommonComponent.ui_data.container_player_resource.visible = true;

                this.step = step;
            }
        }
    };

}