import * as PIXI from 'pixi.js';

import altasBoard from "../assets/img/main.json";
import altasBoardImage from "../assets/img/main.png";



function progress(loader, resource) {
    //console.log(loader.progress + "% loaded");
}

function asset(loader, resource) {
    //console.log("asset loaded " + resource.name);
}

function error(e) {
    console.error("load error", e);
}

export const startLoader = function (complete) {

    PIXI.Loader.shared.add(altasBoardImage).load(() => {
        const sheet = new PIXI.Spritesheet(
            PIXI.Loader.shared.resources[altasBoardImage].texture.baseTexture,
            altasBoard
        );
        sheet.parse((...args) => {
           // console.log("args", args);
        });
        complete(sheet);
    });



    //console.log(MainResources);
    let loader = PIXI.Loader.shared;
    //loader.add("main_resources", '../assets/img/main.json');
    loader.onProgress.add(() => {
        //console.log('onProgress');
    }); // called once per loaded/errored file
    loader.onError.add(() => {
       // console.log('onError');
    }); // called once per errored file
    loader.onLoad.add(() => {
        //console.log('onLoad');
    }); // called once per loaded file
    loader.onComplete.add(() => {
       // console.log('onComplete');
    }); // called once when the queued resources all load.
    loader.load();
};

